import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Masthead from "../components/masthead";
import BannerText from "../components/banner-text";
import MasonryGallery from "../components/masonry-gallery";

export default function ServiceTemplate({ data }) {
    const { markdownRemark } = data;
    const { path, title, featured_image } = markdownRemark.frontmatter;
    const { html } = markdownRemark;

    let galleryImages = Object.assign({}, data.galleryImages.frontmatter);

    for (let key in galleryImages) {
        if (galleryImages.hasOwnProperty(key) && galleryImages[key] && galleryImages[key].childImageSharp) {
            galleryImages[key] = galleryImages[key].childImageSharp.fluid;
        }
    }

    const contact = {
        email: data.site.siteMetadata.email,
        tel: data.site.siteMetadata.telephone,
        mob: data.site.siteMetadata.mobile
    };

    return (
        <Layout>
            <SEO title={title}/>
            <Masthead title={title} image={featured_image.childImageSharp.fluid}/>

            <div className="pb-12 max-w-4xl text-center prose container md:pb-24" dangerouslySetInnerHTML={{ __html: html }}></div>

            <BannerText title="Request a quote"
                        summary={`Call our office <a href="tel:${contact.tel.replace(/\s+/g, '')}">${contact.tel}</a>, mobile <a href="tel:${contact.mob.replace(/\s+/g, '')}">${contact.mob}</a> or email <a href="mailto:${contact.email}?subject=Enquiry">${contact.email}</a>`}
                        background={data.backgroundPhones.childImageSharp.fluid}
                        arrowPosition="none"/>

            <MasonryGallery images={galleryImages} text="View more" link={path + '/gallery'}/>
        </Layout>
    )
}

export const query = graphql`
    query($pathSlug: String!, $masonryGalleryPath: String!) {
        site {
            siteMetadata {
                email
                telephone
                mobile
            }
        }
        markdownRemark(frontmatter: { path: { eq: $pathSlug } }) {
            html
            frontmatter {
                path,
                title,
                featured_image {
                    childImageSharp {
                        fluid(maxWidth: 800, quality: 90) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
        backgroundPhones: file(relativePath: {eq: "background-phones.png"}) {
            childImageSharp {
                fluid(maxWidth: 1400, quality: 90) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        galleryImages: markdownRemark(fileAbsolutePath: {glob: $masonryGalleryPath}) {
            frontmatter {
                image_one {
                    childImageSharp {
                        fluid(maxWidth: 800, quality: 90) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                image_two {
                    childImageSharp {
                        fluid(maxWidth: 1000, quality: 90) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                image_three {
                    childImageSharp {
                        fluid(maxWidth: 800, quality: 90) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                image_four {
                    childImageSharp {
                        fluid(maxWidth: 800, quality: 90) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                image_five {
                    childImageSharp {
                        fluid(maxWidth: 1000, quality: 90) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }
`;
